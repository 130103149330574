import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
// import { Tooltip } from 'bootstrap';
import '../css/style.css';

@customElement('address-component')
export class AddressComponent extends LitElement {
    @property({ type: String }) address: string = 'SPL11111111111111111111111111';

    static override styles = css`

  `;

    override firstUpdated() {
        const tooltipTriggerList = [].slice.call(this.shadowRoot?.querySelectorAll('[data-bs-toggle="tooltip"]') || []);
        tooltipTriggerList.map((tooltipTriggerEl) => {
            //return new Tooltip(tooltipTriggerEl);
        });

        this.shadowRoot?.getElementById('ca-address')?.addEventListener('click', () => {
            const textarea = document.createElement('textarea');
            textarea.value = this.address;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);

            const notyf = new Notyf();
            notyf.success('copied CA address to clipboard');
        });
    }

    override render() {
        return html`
      <div class="col-12 text-center" style="margin-top: 2rem;">
        <copied-address></copied-address>
        <h1>
          <span class="vibrant-text"><strong>CA: </strong></span>
          <span id="ca-address" class="vibrant-text" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to copy the token address">
            <strong>${this.address}</strong>
          </span>
        </h1>
      </div>
    `;
    }
}